<template>
  <div class="layout_common financial_payment">
    <div class="main_box">
      <div class="main_box_con">
        <!-- <btnComp btnType="text" style="position: relative;left:-20px"  @click="back">＜ 返回</btnComp> -->
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <el-table-column slot="time" label="预约时间/验收时间/到场时间" width="210">
            <template v-slot="scope">
              <div>{{ handleDate(scope.row.appointmentVisitTime) }}</div>
              <div>{{ handleDate(scope.row.checkTime) }}</div>
              <div>{{ handleDate(scope.row.arrivalTime) }}</div>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/common'
import { statPayBillDetail } from '@/api'
export default {
  data () {
    return {
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      theadName: [
        { prop: 'statusName', label: '状态' },
        { prop: 'postSaleOrderCode', label: '工单编号' },
        { prop: 'categoryName', label: '类目' },
        { prop: 'storeName', label: '商家' },
        { prop: 'serviceStationName', label: '服务站' },
        { prop: 'dispatchName', label: '调度人' },
        { prop: 'provinceCityRegion', label: '服务区域' },
        { prop: 'detailAddress', label: '现场地址' },
        { prop: 'price', label: '金额' },
        { slot: 'time' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        orderPaymentId: this.$route.query.id
      }
      statPayBillDetail(params).then(res => {
        if (res.code === '1') {
          this.tableData = res.data
        }
      })
    },
    // 处理时间
    handleDate (date) {
      return date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '-'
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>

<style></style>
